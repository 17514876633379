import styled from "styled-components";
import { ButtonVariant } from "./ButtonVariant";
import Queries from "../../Utils/MediaQueries";

type Props = {
  className?: string;
  children?: any;
  text?: string;
  variant?: any;
  onClick?: () => void;
};

type ButtonProps = {
  variant: string;
};

const StyledButton = styled.button<ButtonProps>`
  border: 0;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  width: 100%;

  background-color: var(--blue);
  color: var(--white);

  background-color: ${(props) =>
    props.variant === ButtonVariant.SECONDARY && "transparent"};
  color: ${(props) =>
    props.variant === ButtonVariant.SECONDARY && "var(--blue)"};
  ${(props) =>
    props.variant === ButtonVariant.SECONDARY &&
    "border: 2px solid var(--blue)"};

  background-color: ${(props) =>
    props.variant === ButtonVariant.ATTENTION && "var(--yellow)"};
  color: ${(props) =>
    props.variant === ButtonVariant.ATTENTION && "var(--white)"};

  background-color: ${(props) =>
    props.variant === ButtonVariant.WARNING && "var(--red)"};
  color: ${(props) =>
    props.variant === ButtonVariant.WARNING && "var(--white)"};
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  @media ${Queries.m} {
    width: auto;
  }
`;

const Button = ({
  className,
  text,
  variant = ButtonVariant.PRIMARY,
  children,
  onClick,
}: Props) => (
  <StyledButton className={className} variant={variant}>
    {text ?? children}
  </StyledButton>
);

export default Button;
