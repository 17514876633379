import { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../../Images/Logo.png";
import MenuCart from "../MenuCart/MenuCart";
import Hamburger from "../Hamburger/Hamburger";
import Queries from "../../Utils/MediaQueries";

type MenuProps = {
  $isontop: boolean;
};

const StyledMenu = styled.nav<MenuProps>`
  z-index: 1;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  ${(props) => (!props.$isontop ? "background-color: rgba(0,0,0,0.4)" : "")};
  transition: 400ms;
  height: 60px;
`;
const StyledLogoContainer = styled.a`
  &:hover {
    opacity: 1;
    text-decoration: none;
    border: none;
  }
  img {
    width: 70px;
  }
`;

const StyledHamburger = styled(Hamburger)`
  @media ${Queries.m} {
    display: none;
  }
`;

const StyledMenuItems = styled.div`
  display: none;
  gap: 10px;
  width: auto;
  align-items: center;
  a {
    padding: 15px;
    display: flex;
    align-items: center;
    color: var(--white);
    text-shadow: var(--black) 1px 0 10px;
    &:hover {
      border: none;
    }
  }
  @media ${Queries.m} {
    display: flex;
  }
`;

const StyledMenuCart = styled(MenuCart)`
  margin-left: auto;
`;

const StyledMobileMenuItems = styled.div`
  padding-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledMobileMenuItem = styled.a`
  padding: 20px 20px;
  &:hover {
    background-color: var(--lightGray);
    opacity: 1;
    border: none;
  }
`;

const Menu = () => {
  const [isTop, setIsTop] = useState(true);

  const onScroll = () => {
    setIsTop(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <StyledMenu $isontop={isTop}>
      <StyledLogoContainer href="/">
        <img src={Logo} alt="GediAstro" />
      </StyledLogoContainer>
      <StyledMenuCart />
      <StyledMenuItems>
        <a href="/overview">Konst</a>
        <a href="/about">Om mig</a>
        <a href="/design">Design</a>
      </StyledMenuItems>
      <StyledHamburger>
        <StyledMobileMenuItems>
          <StyledMobileMenuItem href="/overview">Konst</StyledMobileMenuItem>
          <StyledMobileMenuItem href="/about">Om mig</StyledMobileMenuItem>
          <StyledMobileMenuItem href="/design">Design</StyledMobileMenuItem>
        </StyledMobileMenuItems>
      </StyledHamburger>
    </StyledMenu>
  );
};

export default Menu;
