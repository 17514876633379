import styled from "styled-components";
import Button from "../Button/Button";
import Queries from "../../Utils/MediaQueries";

type Props = {
  title: string;
  text: string;
  image: HTMLImageElement;
  imageDesktop: HTMLImageElement;
  action?: () => void;
  buttonText?: string;
};

type HeroProps = {
  bg: string;
  bgl: string;
  queries: any;
};

const StyledHero = styled.div<HeroProps>`
  position: relative;
  height: 70vh;
  text-align: center;
  background-color: --var(black);
  background: url(${(props) => props.bg}) no-repeat center center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 42px;
  color: var(--white);
  text-shadow: var(--black) 1px 0 10px;
  background-size: 100% auto;
  animation: bgZoom 180s infinite;

  @keyframes bgZoom {
    50% {
      background-size: 110% auto;
    }
  }

  @media ${Queries.m} {
    background: url(${(props) => props.bgl}) no-repeat center center;
  }
`;

const StyledHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px;
  margin-bottom: 50px;
  text-align: center;
  @media ${Queries.m} {
    text-align: left;
    max-width: 40%;
  }
  @media ${Queries.l} {
    text-align: justify;
  }
`;

const StyledHeroText = styled.p`
  font-size: 1.2rem;
  @media ${Queries.l} {
    font-size: 1.5rem;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  align-self: flex-start;
`;

const Hero = ({
  title = "",
  text,
  image,
  imageDesktop,
  action,
  buttonText = "Klicka här",
}: Props) => {
  return (
    <StyledHero
      queries={Queries}
      bg={image as unknown as string}
      bgl={imageDesktop as unknown as string}
    >
      <StyledHeroContent>
        <h1>{title}</h1>
        <StyledHeroText>{text}</StyledHeroText>
        {action && <StyledButton onClick={action} text={buttonText} />}
      </StyledHeroContent>
    </StyledHero>
  );
};

export default Hero;
