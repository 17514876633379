import { useState } from "react";
import styled from "styled-components";
import CartIcon from "../../Icons/cart.svg";

type Props = {
  className?: string;
};

const StyledMenuCart = styled.button`
  border: 0;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 30px;
  background-color: var(--yellow);
  max-height: 25px;
  padding: 15px 12px;
  border-radius: 25px;
  &:hover {
    cursor: pointer;
  }
`;
const StyledIcon = styled.img`
  width: 20px;
`;

const MenuCart = ({ className }: Props) => {
  const [cartItems, setCartItems] = useState(2);
  return (
    <StyledMenuCart className={className} onClick={() => {}}>
      <StyledIcon src={CartIcon} alt="" />
      <p>{cartItems}</p>
    </StyledMenuCart>
  );
};

export default MenuCart;
