import styled from "styled-components";
import FrameIcon from "../../Icons/usp-frame.svg";

const StyledUspSection = styled.div`
  background-color: var(--black);
  padding: 32px 0;
  display: flex;
  justify-content: center;
  gap: 200px;
`;
const StyledUsp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIcon = styled.img`
  height: 80px;
`;

const StyledText = styled.p`
  margin-top: 10px;
  color: var(--white);
`;

const UspSection = () => (
  <StyledUspSection>
    <StyledUsp>
      <StyledIcon src={FrameIcon} alt="Frame" />
      <StyledText>Printar av hög kvalitét</StyledText>
    </StyledUsp>
    <StyledUsp>
      <StyledIcon src={FrameIcon} alt="Frame" />
      <StyledText>Personlig leverans</StyledText>
    </StyledUsp>
    <StyledUsp>
      <StyledIcon src={FrameIcon} alt="Frame" />
      <StyledText>Skapa en unik arbetsplats</StyledText>
    </StyledUsp>
  </StyledUspSection>
);

export default UspSection;
