import styled from "styled-components";
import FilterSection from "../../Components/FilterSection/FilterSection";
import Menu from "../../Components/Menu/Menu";
import Section from "../../Components/Section/Section";
import SlimHero from "../../Components/SlimHero/SlimHero";
import Tile from "../../Components/Tile/Tile";
import SlimHeroImg from "../../Images/SlimHero2.jpg";
import ArtworkImage from "../../Images/ghost.jpg";
import artworks from "../../data/artworks.json";
import Queries from "../../Utils/MediaQueries";

const StyledImage = styled.img`
  border: 4px solid var(--white);
`;

const StyledArtSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2160px;
  margin: 0 auto;
  @media ${Queries.l} {
    flex-direction: row;
  }
`;

const StyledArtCol = styled.div`
  display: flex;
  flex-direction: column;
  @media ${Queries.l} {
    flex-basis: 50%;
    width: 50%;
  }
`;

const StyledImgLink = styled.a`
  &:hover {
    border: none;
  }
`;

const OverviewPage = () => {
  return (
    <>
      <Menu />
      <SlimHero image={SlimHeroImg} title="Fotografier" />
      <StyledArtSection>
        <StyledArtCol>
          {artworks
            ?.slice(0, Math.floor((artworks?.length ?? 0) / 2))
            ?.map((artwork) => {
              const img = require(`./${artwork.name}-thumb.jpg`);
              return (
                <StyledImgLink href={`/details/${artwork.id}`}>
                  <StyledImage
                    key={artwork.name}
                    src={img}
                    alt={artwork.name}
                  />
                </StyledImgLink>
              );
            })}
        </StyledArtCol>
        <StyledArtCol>
          {artworks
            ?.slice(Math.floor((artworks?.length ?? 0) / 2), artworks?.length)
            ?.map((artwork) => {
              const img = require(`./${artwork.name}-thumb.jpg`);
              return (
                <StyledImgLink href={`/details/${artwork.id}`}>
                  <StyledImage
                    key={artwork.name}
                    src={img}
                    alt={artwork.name}
                  />
                </StyledImgLink>
              );
            })}
        </StyledArtCol>
      </StyledArtSection>
      <Tile
        image={ArtworkImage}
        title="Hittar du inget som passar?"
        text="Saknar du ett objekt eller en specifik färgskala? Tveka inte att kontakta mig för att specialbeställa ett fotografi som matchar era behov!"
        buttonText="Till kontaktsidan"
        action={() => {}}
      />
    </>
  );
};

export default OverviewPage;
