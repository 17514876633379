import styled from "styled-components";

type Props = {
  title: string;
  image: HTMLImageElement;
};

type SlimHeroProps = {
  bg: string;
};

const StyledSlimHero = styled.div<SlimHeroProps>`
  width: 100%;
  text-align: center;
  background-color: --var(black);
  background: url(${(props) => props.bg}) no-repeat center;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  color: var(--white);
  text-shadow: var(--black) 1px 0 10px;
  background-size: 100% auto;
  animation: bgZoom 180s infinite;

  @keyframes bgZoom {
    50% {
      background-size: 130% auto;
    }
  }
`;

const SlimHero = ({ title = "", image }: Props) => (
  <StyledSlimHero bg={image as unknown as string}>
    <h1>{title}</h1>
  </StyledSlimHero>
);

export default SlimHero;
