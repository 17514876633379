import { useParams } from "react-router-dom";

const DetailsPage = () => {
  const { slug } = useParams();
  return (
    <p>
      DetailsPage:
      {slug}
    </p>
  );
};

export default DetailsPage;
